@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";


.bountyAmount {
  font-size: typo.$xl;
  width: fit-content;
  margin-left: auto;
  margin-bottom: spacings.$s-5;
}

.description {
  padding-bottom: spacings.$s-3;
  white-space: break-spaces;

}


.buttons {
  margin-top: spacings.$s-18;
  display: flex;
  justify-content: space-around;
}



.containerTag {
  span + span {
    margin-left: spacings.$s-1;
  }
}
