@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/breakpoints";

.title {
  margin-bottom: spacings.$s-5;

  & + span {
    vertical-align: super;
  }

  @media (max-width: breakpoints.$small) {
    font-size: typo.$xl;
  }
}

.description {
  white-space: break-spaces;
  margin-top: spacings.$s-3;
  margin-bottom: spacings.$s-12;
}

.containerTag {
  margin-top: spacings.$s-3;
  margin-bottom: spacings.$s-10;

  span + span {
    margin-left: spacings.$s-2;
  }
}
