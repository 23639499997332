@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";
@use "src/stylesheets/variables/typo";

$iconSize: calc(1rem + 1vw);
$eye: url(../../assets/eye.svg);
$sun: url(../../assets/sun.svg);

$marks: url(../../assets/marks.svg);
$gappedBorders: linear-gradient(
      to right,
      transparent 10%,
      black 10%,
      black 45%,
      transparent 45%,
      transparent 55%,
      black 55%,
      black 90%,
      transparent 90%
    )
    0 100%,
  linear-gradient(
      to right,
      transparent 10%,
      black 10%,
      black 45%,
      transparent 45%,
      transparent 55%,
      black 55%,
      black 90%,
      transparent 90%
    )
    0 0,
  linear-gradient(to bottom, transparent 10%, black 10%, black 90%, transparent 90%) 100% 0,
  linear-gradient(to bottom, transparent 10%, black 10%, black 90%, transparent 90%) 0 0;

.home {
  // Layout

  display: block;

  section.leftFloating,
  section.rightFloating {
    display: flex;
    flex-direction: column;

    figure {
      margin-left: auto;
      margin-right: auto;
    }
  }

  section.twoColumnsMerging {
    > div {
      display: grid;
      grid-template-columns: 1fr;
      //margin-top: 4 * spacings.$s-4;
      margin-bottom: 4 * spacings.$s-4;

      p {
      }
    }
  }

  @media (min-width: breakpoints.$small) {
    div.twoColumn,
    div.threeColumn {
      column-gap: 2rem;
      text-align: justify;
    }
    div.twoColumn {
      column-count: 2;
    }

    div.threeColumn {
      column-count: 2;
    }

    section.leftFloating,
    section.rightFloating {
      display: flex;
      flex-direction: column;

      h1 {
        flex: 1;
        margin-block-start: 0;
        margin-right: spacings.$s-8;
        margin-top: -0.6rem;
      }

      & > div {
        flex: 2;
      }
    }

    section.rightFloating {
      figure {
        margin-left: spacings.$s-8;
        float: right;
      }
    }

    section.leftFloating {
      figure {
        margin-right: spacings.$s-8;
        float: left;
      }
    }

    section.twoColumnsMerging {
      & > div {
        grid-template-columns: 1fr 1fr;
      }
      & > div > p {
        grid-column: span 2;
      }
    }
  }

  @media (min-width: breakpoints.$large) {
    div.twoColumn {
      column-count: 2;
    }

    div.threeColumn {
      column-count: 3;
    }

    section.leftFloating,
    section.rightFloating {
      display: flex;
      flex-direction: row;
    }
  }
}

.homeNav {
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8 * spacings.$spacing;
  padding-bottom: 10px;
  background-color: hsl(var(--background));
  z-index: 99;
}
// Styling
.home {
  position: relative;
  word-break: break-word;

  p:global(.has-dropped-letter)::first-letter {
    float: left;
    font-size: 3rem;
    line-height: 0.87;
    text-transform: uppercase;
    margin-right: 0.3rem;
  }
  .logo {
    height: calc(70dvh - 5rem);
    width: 100%;
    margin-bottom: 2.23rem;
    padding: 8dvh;
  }

  div.twoColumn {
    margin-bottom: 20 * spacings.$spacing;
  }

  div.threeColumn {
    margin-top: 7 * spacings.$spacing;
    margin-bottom: 32 * spacings.$spacing;
  }

  div.threeColumn + em {
    display: block;
    text-align: center;
  }

  & > section {
    padding-top: calc(4vw + 10 * spacings.$spacing);
    padding-bottom: calc(4vw + 16 * spacings.$spacing);

    &:first-of-type {
      padding-top: calc(4vw + 10 * spacings.$spacing);
      padding-bottom: calc(4vw + 5 * spacings.$spacing);
    }

    @media (max-width: breakpoints.$small) {
      &.leftFloating,
      &.principles {
        background: linear-gradient(
            to right,
            black 0%,
            black 45%,
            transparent 45%,
            transparent 55%,
            black 55%,
            black 100%
          )
          0 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        position: relative;

        &::after {
          content: ""; // Required for pseudo elements
          position: absolute;
          display: block;
          height: $iconSize;
          width: $iconSize;
          background-size: $iconSize $iconSize;
          background-image: $eye;
          background-repeat: no-repeat;
          left: 50%;
          transform: translateX(-50%);
          bottom: calc(-1 * $iconSize/2);
        }
      }
    }

    &.rightFloating,
    &.twoColumnsMerging,
    &:has(> figure) {
      background: linear-gradient(
          to right,
          black 0%,
          black 45%,
          transparent 45%,
          transparent 55%,
          black 55%,
          black 100%
        )
        0 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      position: relative;

      &::after {
        content: ""; // Required for pseudo elements
        position: absolute;
        display: block;
        height: $iconSize;
        width: $iconSize;
        background-size: $iconSize $iconSize;
        background-image: $eye;
        background-repeat: no-repeat;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(-1 * $iconSize/2);
      }
    }

    &.principles {
      text-align: center;
      & > div {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16 * spacings.$spacing;
      }

      h1 {
        margin-bottom: 30 * spacings.$spacing;
      }

      img {
        width: 4.5rem;
      }

      @media (min-width: breakpoints.$small) {
        & > div {
          grid-template-columns: 1fr 1fr;
          grid-gap: 0;
        }

        & > div > div {
          padding: 12 * spacings.$spacing 18 * spacings.$spacing;
        }

        & > div > div:nth-of-type(odd) {
          background: linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 100% 0;
          background-repeat: no-repeat;
          background-size: 100% 1px, 1px 100%;
        }

        & > div > div:nth-of-type(odd):not(:first-of-type) {
          position: relative;

          &::before,
          &::after {
            content: ""; // Required for pseudo elements
            position: absolute;
            display: block;
            height: $iconSize;
            width: $iconSize;
            background-size: $iconSize $iconSize;
            background-image: $eye;
            background-repeat: no-repeat;
            left: 0%;
            transform: translateX(-50%);
          }

          &::before {
            top: calc(-1 * $iconSize/2);
          }

          &::after {
            bottom: calc(-1 * $iconSize/2);
          }
        }

        & > div > div:nth-of-type(even) {
          background: linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 100% 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 0 0;
          background-repeat: no-repeat;
          background-size: 100% 1px, 1px 100%, 1px 100%;
        }

        & > div > div:nth-last-child(2) {
          background: linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 100%,
            linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 100% 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 0 0;
          background-repeat: no-repeat;
          background-size: 100% 1px, 100% 1px, 1px 100%, 1px 100%;
        }

        & > div > div:last-child {
          background: linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 100%,
            linear-gradient(to right, transparent 10%, black 10%, black 90%, transparent 90%) 0 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 100% 0; /* right border */
          background-repeat: no-repeat;
          background-size: 100% 1px, 100% 1px, 1px 100%;
        }

        & > div > div:first-of-type {
          grid-column: span 2;
          position: relative;

          background: linear-gradient(
                to right,
                transparent 10%,
                black 10%,
                black 45%,
                transparent 45%,
                transparent 55%,
                black 55%,
                black 90%,
                transparent 90%
              )
              0 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 100% 0,
            linear-gradient(to bottom, transparent 20%, black 20%, black 80%, transparent 80%) 0 0;
          background-repeat: no-repeat;
          background-size: 100% 1px, 1px 100%, 1px 100%;

          &::before,
          &::after {
            position: absolute;
            content: $eye;
            content: "";
            display: block;
            height: $iconSize;
            width: $iconSize;
            background-size: $iconSize $iconSize;
            background-image: $eye;
            background-repeat: no-repeat;
          }

          &::before {
            top: calc(-1 * $iconSize/2);
            left: 50%;
            transform: translateX(-50%);
          }

          &::after {
            bottom: calc(-1 * $iconSize/2);
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    &:not(.principles) {
      figure {
        text-align: center;

        img {
          margin-bottom: 7 * spacings.$spacing;
        }

        &::after {
          content: $marks;
        }
      }
    }

    &.leftFloating,
    &.rightFloating {
      figure {
        max-width: 50%;
        margin-bottom: spacings.$s-4;
        margin-top: spacings.$s-8;
      }
      p {
        text-align: justify;
      }
    }

    @media (min-width: breakpoints.$small) {
      &.twoColumnsMerging {
        position: relative;
        h1::after {
          content: $sun;
          position: absolute;
          top: 10%;
          left: 70%;
        }
      }
    }
  }

  img {
    max-width: 100%;
  }

  & > header {
    padding: min(3vw, 14 * spacings.$spacing) min(6vw, 28 * spacings.$spacing);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;

    background: $gappedBorders;
    background-repeat: no-repeat;
    background-size: 100% 1px, 100% 1px, 1px 100%, 1px 100%;

    &::before,
    &::after {
      position: absolute;
      content: $eye;
      content: "";
      display: block;
      height: $iconSize;
      width: $iconSize;
      background-size: $iconSize $iconSize;
      background-image: $eye;
      background-repeat: no-repeat;
    }

    &::before {
      top: calc(-1 * $iconSize/2);
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      bottom: calc(-1 * $iconSize/2);
      left: 50%;
      transform: translateX(-50%);
    }

    h1 {
      font-size: 3rem;
    }
    p {
      font-size: typo.$s;
    }
  }
}
