@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/breakpoints";

.appealPeriod {
  display: flex;
  flex-direction: column;
  align-items: center;

  .crowdFundingPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: spacings.$s-18;

    @media (min-width: breakpoints.$small) {
      padding: spacings.$s-4 spacings.$s-7;
      border: 1px solid hsl(var(--english-breakfast-800));
      border-radius: 4px;
    }

    .title {
      margin-bottom: spacings.$s-4;
      font-size: typo.$m;
    }

    .appealOptions {
      display: flex;
      justify-content: space-between;
      gap: spacings.$s-4;
      flex: 1 0 0;
    }

    .footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;

      .label {
        display: flex;
        align-items: center;
        gap: spacings.$s-1;
        margin-bottom: spacings.$s-4;
        font-size: typo.$xs;

        @media (min-width: breakpoints.$large) {
          margin-bottom: spacings.$s-2;
          align-self: end;
        }
        .colorBox {
          width: spacings.$s-3;
          height: spacings.$s-3;
          background-color: hsl(313, 74%, 35%);
        }
      }

      .fundInput {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (min-width: breakpoints.$large) {
          width: max-content;
          gap: spacings.$s-2;
        }

        input {
          margin-top: 0;
        }
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-bottom: spacings.$s-5;
    width: 100%;
  }

  .juryDecision {
    display: flex;
    justify-content: center;
    margin-bottom: spacings.$s-12;

    span {
      margin-left: spacings.$s-2;
      color: hsl(var(--red-reign-600));
    }
  }
}

.appealFundingModal {
  width: 90%;
  max-width: 1200px;
}
