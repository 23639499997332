@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.court {
  margin: spacings.$s-12 0;

  .periods {
    display: flex;
    justify-content: space-between;
    margin-top: spacings.$s-6;
  }
}
