@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/breakpoints";

.containerKeyMetrics {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$small) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: spacings.$s-7;
  }
}

.trustScore {
  font-size: typo.$m;

  .unit {
    margin-left: spacings.$s-1;
    font-size: typo.$m;
  }
  @media (min-width: breakpoints.$small) {
    font-size: typo.$xl;

    .unit {
      font-size: typo.$xl;
    }
  }
}

.bountyAmount {
  font-size: typo.$m;

  @media (min-width: breakpoints.$small) {
    font-size: typo.$xl;
    text-align: end;
  }
}
