@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

.eventLog {
  max-width: 95%;

  @media (min-width: breakpoints.$small) {
    max-width: 80vw;
  }

  .title {
    font-size: typo.$xl;
    margin-top: spacings.$s-7;
  }

  .eventCard {
    margin: spacings.$s-7 auto;
    padding: spacings.$s-7 spacings.$s-4 spacings.$s-5;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid hsl(var(--smooth-coffee-500));
    border-radius: 0.5rem;

    .cardTitle {
      font-size: typo.$m;
      margin-bottom: spacings.$s-3;
    }

    .date {
      margin-bottom: spacings.$s-7;
    }

    .expandButton {
      width: spacings.$s-6;
      height: spacings.$s-6;
    }

    a {
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      font-size: typo.$m;
      text-align: left;
      border-bottom: 3px solid hsl(var(--english-breakfast-500));
    }

    tbody {
      display: block;
      max-height: 600px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
        margin-left: 10px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: hsl(var(--english-breakfast-500), 0.9);
        border-radius: 4px;
        &:hover {
          background-color: hsl(var(--english-breakfast-500));
        }
      }
    }

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;

      &:not(:last-child) {
        border-bottom: 1px solid hsl(var(--english-breakfast-500));
      }

      &.expanded {
        border-bottom: none;
      }

      td {
        padding: spacings.$s-4 spacings.$s-7 spacings.$s-4 0;
        font-size: typo.$xs;
        text-transform: none;

        &.expanded {
          padding-top: 0;
        }
        .expandButton {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    th,
    td {
      padding: spacings.$s-4 spacings.$s-7 spacings.$s-4 0;
      text-transform: capitalize;

      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 35%;
      }

      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 30%;
        padding-right: 0;
      }
    }
  }
}

.evidenceDisplay {
  padding: spacings.$s-4 0;
  border: 1px solid hsl(var(--english-breakfast-400));
  border-radius: 0.25rem;
  text-transform: none;

  .header {
    font-size: typo.$s;
    padding-bottom: spacings.$s-3;
    display: flex;
    justify-content: space-between;
    text-align: left;

    ::first-letter {
      text-transform: capitalize;
    }
  }

  .description {
    font-size: typo.$xs;
    padding-top: spacings.$s-3;
    text-align: left;
    ::first-letter {
      text-transform: capitalize;
    }
  }

  .header,
  .description {
    padding-left: spacings.$s-4;
    padding-right: spacings.$s-4;
  }
}
