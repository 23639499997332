@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

.header {
  .logo {
    height: calc(11vw + 2rem);
  }

  margin-bottom: spacings.$s-5;

  text-align: center;

  h1 {
    font-size: calc(typo.$xxl + 3vw);
    margin-bottom: 0;
  }

  .subtitleContainer {
    margin: auto;
    width: fit-content;
  }

  .subtitle {
    padding: 0 1rem;
    font-size: typo.$m;
    margin-bottom: spacings.$s-2;
  }

  .subtitleDate {
    margin-top: spacings.$s-2;
    font-size: typo.$s;
  }

  @media (min-width: breakpoints.$small) {
    .subtitle {
      font-size: typo.$l;
    }
  }
}
