@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

$body-margin: 2.5rem;

@mixin hideBelowTabletSize {
  @media only screen and (max-width: breakpoints.$large) {
    display: none;
  }
}

.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  z-index: 1000;
  border-bottom: 1px solid hsl(var(--english-breakfast-600));
  border-top: 1px solid hsl(var(--english-breakfast-600));

  &.fixedAtTop {
    position: fixed;
    top: 0;
  }

  .nav {
    @include hideBelowTabletSize();
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      color: #333;
      text-decoration: none;
      margin-right: 20px;
      font-size: typo.$s;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      display: none;
    }
  }

  .navEthereum {
    display: flex;
    flex-direction: row;
  }
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 100vh;
  background-color: hsl(var(--background));
  background-repeat: repeat;
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.overlayNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    visibility: hidden;
  }

  &.visible {
    a {
      color: #333;
      text-decoration: none;
      margin-bottom: 20px;
      font-size: typo.$l;

      animation: fadeIn 0.3s ease-out;
      animation-fill-mode: forwards;

      &:hover {
        text-decoration: underline;
      }
    }

    a:nth-child(1) {
      animation-delay: 0.2s;
    }

    a:nth-child(2) {
      animation-delay: 0.3s;
    }

    a:nth-child(3) {
      animation-delay: 0.4s;
    }

    a:nth-child(4) {
      animation-delay: 0.5s;
    }

    a:nth-child(5) {
      animation-delay: 0.6s;
    }

    a:nth-child(6) {
      animation-delay: 0.7s;
    }
  }

  h2 {
    font-size: typo.$l;
    font-weight: bold;
    margin: 0 0 20px;
  }
}
