@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/breakpoints";

.crowdfundingCard {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: spacings.$s-3;
  flex-grow: 1;
  padding: spacings.$s-4;
  margin-bottom: spacings.$s-5;
  border: 1px solid hsl(var(--english-breakfast-900));
  border-radius: 4px;

  grid-template-areas:
    "radio"
    "indicator"
    "ethValue"
    "appealDeadline";

  @media (min-width: breakpoints.$large) {
    gap: spacings.$s-3;
    justify-items: flex-start;
    grid-template-areas:
      "radio . ethValue"
      "indicator indicator indicator"
      "appealDeadline appealDeadline .";
  }

  .radioButton {
    grid-area: radio;

    :global(.ant-radio-inner) {
      border-color: hsl(var(--english-breakfast-800));
      background-color: transparent;

      &::after {
        background-color: hsl(var(--english-breakfast-800));
      }
    }
    span {
      font-weight: bold;
      font-size: typo.$s;
    }
  }

  .ethValue {
    grid-area: ethValue;
    font-size: typo.$xl;
    color: hsl(var(--english-breakfast-600));
    @media (min-width: breakpoints.$large) {
      justify-self: end;
    }
  }

  .indicator {
    grid-area: indicator;
    margin-top: spacings.$s-3;
    @media (min-width: breakpoints.$large) {
      width: 100%;
      margin-top: 0;
    }
  }

  .appealDeadline {
    grid-area: appealDeadline;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    font-size: typo.$s;
    span {
      font-weight: bold;
      color: hsl(var(--english-breakfast-600));
    }

    @media (min-width: breakpoints.$large) {
      flex-direction: row;
      span {
        margin-left: spacings.$s-2;
      }
    }
  }
}
