@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.containerButtons {
  display: flex;
  justify-content: center;

  margin-bottom: spacings.$s-20;

  button + button {
    margin-left: 1rem;
  }
}

.image {
  width: 100%;
  height: 100px;
}
