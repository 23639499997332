@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

@function shadowTemplate($color) {
  @return 0rem 0.25rem 0.5rem 0rem hsl($color, 0.25);
}

.select {
  position: relative;
  height: 100%;
  .selectedOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-size: typo.$xs;
    padding: spacings.$s-2 spacings.$s-4;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid hsl(var(--english-breakfast-800));
    box-shadow: shadowTemplate(var(--english-breakfast-500));
    cursor: pointer;

    span {
      margin-right: spacings.$s-8;
    }

    svg {
      margin-top: 4px;
      transition: transform 0.3s ease;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      border-color: hsl(var(--english-breakfast-600));
    }
  }

  .optionList {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 0;
    margin-top: spacings.$s-1;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    background-color: hsl(var(--background));
    border: 1px solid hsl(var(--english-breakfast-800));
    border-radius: 4px;
    box-shadow: shadowTemplate(var(--english-breakfast-500));

    .option {
      padding: spacings.$s-2 spacings.$s-4;
      font-size: typo.$xs;
      color: hsl(var(--dark-orchestra-600));
      cursor: pointer;

      &:hover {
        background-color: hsl(var(--english-breakfast-800));
        color: hsl(var(--starbright-600));
      }
    }
  }

  &.open {
    .selectedOption {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
