@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

.containerItems {
  @media (min-width: breakpoints.$large) {
    display: grid;
    column-gap: spacings.$s-6;
    row-gap: spacings.$s-6;
    grid-template-columns: repeat(4, minmax(160px, 1fr));

    & > :nth-child(4n + 2) {
      grid-column: span 2;
    }

    & > :nth-child(4n) {
      grid-column: span 4;
      grid-row: span 1;
    }

    & > :nth-child(4n + 1),
    & > :nth-child(4n + 2),
    & > :nth-child(4n + 3) {
      grid-row: span 2;
    }
  }
}
