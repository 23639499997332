@use "src/stylesheets/variables/typo";

.footer {
  min-height: 4rem;
  max-height: 10rem;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  &>span, &>a {
    align-self: center;
  }
}

.copyright {
  font-size: typo.$xxs;
  justify-self: center;
}

.containerText {
  justify-self: left;
}

.containerSocialAndCopyright {
  display: flex;
  flex-direction: column;
  justify-self: right;
  align-self: center;

}

.social {
  align-self: self-end;
  a + a {
    margin-left: calc(1vw + 0.5rem);
  }
}
