@use "src/stylesheets/variables/breakpoints";

$thickness: 3px;
$gap: 6px;
$height: 3 * $thickness + 2 * $gap;

.burgerMenu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: $height;
  width: 1.64 * $height;
  cursor: pointer;

  span {
    width: 100%;
    height: 3px;
    background-color: #333;
    border-radius: 3px;
  }

  @media only screen and (max-width: breakpoints.$large) {
    display: flex;
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: translateY($thickness + $gap) translateX(0) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-$thickness - $gap) translateX(0) rotate(-45deg);
      }
    }
  }
}
