@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.evidenceModal {
  max-width: 80vw;

  .title {
    font-size: typo.$xl;
    margin-top: spacings.$s-7;
    margin-bottom: spacings.$s-4;
  }

  .label {
    font-size: typo.$m;
    margin-top: spacings.$s-4;
  }
  .evidenceTitle {
    width: 100%;
    margin-bottom: spacings.$s-4;
  }

  .evidenceDescription {
    width: 100%;
    margin-bottom: spacings.$s-4;
  }
  .fileUpload {
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: center;

    .fileUploadArea {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: spacings.$s-1;
      padding: spacings.$s-1 0;
      width: 100%;
      height: spacings.$s-10;
      border: 1px dashed black;
      border-radius: spacings.$s-1;
    }
    .dragOver {
      border: 1px solid #534119;
    }
  }

  .button {
    margin-top: spacings.$s-18;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
