@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";

.tabs {
  display: inline-flex;
  margin-top: spacings.$s-3;
  margin-bottom: spacings.$s-5;
  border: 1px solid hsl(var(--smooth-coffee-600));
  border-radius: 0.25rem;

  .tabButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: spacings.$s-2 spacings.$s-2;
    font-size: typo.$xxs;
    font-weight: 600;
    color: hsl(var(--smooth-coffee-600));
    background-color: transparent;
    border-right: 1px solid hsl(var(--smooth-coffee-600));
    cursor: pointer;

    input[type="radio"] {
      display: none;
    }

    &:first-child {
      padding-left: spacings.$s-3;
    }
    &:last-child {
      border-right: none;
      padding-right: spacings.$s-3;
    }

    &.active {
      background-color: hsl(var(--english-breakfast-700));
      color: hsl(var(--starbright-600));
    }
  }
}
