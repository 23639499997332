@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";

.votingPeriod {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: spacings.$s-20;

  .hiddenVotes {
    margin-top: spacings.$s-2;
  }
  .cardWrapper {
    margin: spacings.$s-4 auto spacings.$s-10;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: spacings.$s-10;

    .voteCard {
      min-width: 120px;
      padding: spacings.$s-6 spacings.$s-3;
      display: flex;
      gap: spacings.$s-4;
      flex-direction: column;
      align-items: center;
      border: 1px solid black;
      border-radius: spacings.$s-1;

      .voteCount {
        font-size: typo.$l;
      }
      .voteOption {
        font-size: typo.$xs;
      }
    }
  }

  .question {
    margin-top: spacings.$s-4;
    margin-bottom: spacings.$s-7;
  }
}
