@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.bountyModal {
  width: 90%;
  max-width: 1200px;

  .title {
    width: 100%;
    margin-top: spacings.$s-7;
  }

  .inputContainer {
    font-size: typo.$s;
  }
  .currentBounty {
    font-style: italic;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: spacings.$s-12;
    margin-bottom: spacings.$s-5;
    width: 100%;
  }
}
