@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/breakpoints";

.arbitrationDetails {
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacings.$s-4;
  }

  .title {
    font-size: typo.$xl;
  }

  .detailsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: spacings.$s-3;
    margin: spacings.$s-8 0;
    color: hsl(var(--english-breakfast-600));

    a {
      text-decoration: underline;
    }

    span {
      display: flex;
      b {
        margin-right: spacings.$s-2;
        color: hsl(var(--dark-orchestra-600));
      }
    }

    @media (min-width: breakpoints.$small) and (max-width: breakpoints.$large) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: spacings.$s-3;

      span:nth-child(even) {
        justify-content: end;
      }
      span:nth-child(odd) {
        justify-content: start;
      }
    }

    @media (min-width: breakpoints.$large) {
      grid-template-columns: repeat(4, 1fr);
      span {
        justify-content: start;
      }
    }
  }
}
