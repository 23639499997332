@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

$brownDarken40: hsl(var(--english-breakfast-800), 0.8);

.listArticlesItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: spacings.$s-6 0;

  @media (min-width: breakpoints.$large) {
    border: 1px solid hsl(var(--english-breakfast-600));
    border-radius: 1rem;
    transition: border-color 0.6s ease-in-out;
    padding: spacings.$s-6;
    margin: 0;

    &:hover {
      border: 1px solid hsl(var(--english-breakfast-700));
    }
  }

  &:hover {
    color: unset;
  }
}

.trustScore {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: spacings.$s-2;
  font-size: typo.$m;
  color: hsl(var(--smooth-coffee-600));
  margin-bottom: spacings.$s-2;
}

.title {
  font-size: typo.$l;
  font-weight: 500;
  color: hsl(var(--dark-orchestra-600));
  margin-right: spacings.$s-2;

  & > span {
    vertical-align: middle;
  }
}

.description {
  margin: spacings.$s-4 0;
}

.createdAt {
  margin-top: auto;
  font-size: typo.$s;
  color: hsl(var(--smooth-coffee-600));
}

.children {
  & > span {
    align-self: center;
  }
}
